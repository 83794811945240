<template>
  <svg viewBox="0 0 193 193" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.3485 106.114C41.4797 106.696 43.679 105.44 44.2608 103.309C44.8425 101.178 43.5865 98.9785 41.4554 98.3967C39.3242 97.8149 37.125 99.0709 36.5432 101.202C35.9614 103.333 37.2174 105.533 39.3485 106.114Z"
      fill="currentColor"
    />
    <path
      d="M78.9676 136.625L69.7682 134.114C68.9861 133.9 68.4548 133.579 68.1743 133.148C67.9461 132.688 67.9388 132.067 68.1523 131.285L79.0742 91.2765C79.2877 90.4945 79.604 89.9838 80.0232 89.7444C80.4948 89.4751 81.1216 89.4471 81.9037 89.6606L91.8439 92.3743C92.5025 92.554 92.9982 92.8442 93.3312 93.2447C93.7053 93.6564 93.9877 94.2421 94.1783 95.0018L96.9877 106.582C97.0924 107.009 97.184 107.322 97.2625 107.52C97.3822 107.73 97.5655 107.868 97.8125 107.935L98.3681 108.087C98.6151 108.155 98.8228 108.123 98.9912 107.992C99.2008 107.872 99.4591 107.655 99.766 107.341L108.008 98.7773C108.558 98.2198 109.079 97.8532 109.569 97.6775C110.1 97.513 110.695 97.5206 111.354 97.7004L121.356 100.431C122.138 100.644 122.643 100.981 122.871 101.442C123.152 101.872 123.185 102.478 122.972 103.26L112.05 143.268C111.836 144.05 111.494 144.576 111.022 144.845C110.603 145.085 110.003 145.098 109.221 144.884L99.7124 142.289C98.9304 142.075 98.3991 141.753 98.1185 141.323C97.8904 140.862 97.8831 140.241 98.0965 139.459L104.029 117.726L98.5496 123.462C97.9881 124.06 97.3949 124.451 96.7699 124.634C96.1973 124.788 95.52 124.758 94.738 124.544L92.2683 123.87C91.4863 123.656 90.867 123.333 90.4106 122.898C90.0066 122.434 89.7149 121.802 89.5354 121.001L87.7299 113.277L81.797 135.009C81.5835 135.791 81.241 136.317 80.7694 136.586C80.3502 136.826 79.7496 136.839 78.9676 136.625Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M193 96.5C193 149.795 149.795 193 96.5 193C43.2045 193 0 149.795 0 96.5C0 43.2045 43.2045 0 96.5 0C149.795 0 193 43.2045 193 96.5ZM36.6533 58.4208C38.1254 54.5354 41.2473 51.5046 45.1745 50.1482L124.991 22.5804C128.123 21.4986 131.539 23.1607 132.621 26.2928L152.209 83.0053C153.29 86.1375 151.628 89.5536 148.496 90.6354L146.166 91.4401L110.424 81.6828L109.708 79.6098C109.444 78.8435 109.074 78.3618 108.6 78.1645C108.152 77.913 107.545 77.9196 106.779 78.1842L102.731 79.5824L93.314 77.0117L100.082 74.6742C100.848 74.4095 101.337 74.0602 101.548 73.6261C101.785 73.1378 101.772 72.5105 101.507 71.7442L99.2716 65.2715C99.007 64.5052 98.6375 64.0235 98.1631 63.8262C97.7151 63.5747 97.1079 63.5812 96.3417 63.8459L84.5455 67.9202L82.9367 63.2622L99.6328 57.4955C100.399 57.2308 100.888 56.8815 101.099 56.4474C101.337 55.9591 101.323 55.3318 101.058 54.5655L98.6138 47.4878C98.3492 46.7216 97.9796 46.2398 97.5052 46.0425C97.0573 45.791 96.4501 45.7976 95.6839 46.0622L67.615 55.757C66.8487 56.0217 66.3468 56.3982 66.1092 56.8865C65.898 57.3206 65.9247 57.9207 66.1894 58.687L70.3537 70.7437L67.1242 69.8621C63.0758 68.7569 58.7452 69.526 55.3249 71.9577L27.7648 91.552C26.6346 92.3556 25.7151 93.3587 25.0272 94.4844C24.4886 92.5053 24.5546 90.3528 25.3257 88.3177L36.6533 58.4208ZM68.7495 72.6769C64.7413 71.5827 60.4541 72.3254 57.0475 74.7041L30.8345 93.0076C26.8766 95.7713 25.4579 100.968 27.4626 105.359L40.7391 134.443C42.4645 138.223 45.7797 141.041 49.7879 142.135L131.25 164.374C134.446 165.246 137.745 163.362 138.618 160.166L154.419 102.284C155.292 99.0871 153.408 95.7881 150.211 94.9155L68.7495 72.6769Z"
      fill="currentColor"
    />
  </svg>

</template>

<script lang="ts">
import Vue from "vue";
import { Component } from 'vue-property-decorator'

@Component({
  name: 'LogoCutout',
})

export default class Logo extends Vue {}
</script>
